import files from "./files.json";
const re = /discord\/(\d{4})(\d{2})(\d{2})_(\d{6})_(.*?)_(.*)/;

const extract = (file) => {
  let [filepath, year, month, day, time, author, filename] =
    re.exec(file) || [];
  month = `${Number(month) + 1}`.padStart(2, "0");
  return {
    date: `${year}-${month}-${day}`,
    time,
    author,
    filename,
    filepath,
  };
};

const imageFilter = (f) => {
  f = f.toLowerCase();
  return f.endsWith(".png") || f.endsWith(".jpg") || f.endsWith(".jpeg");
};
export const byDate = files.filter(imageFilter).reduce((acc, f) => {
  const params = extract(f);
  acc[params.date] = acc[params.date] || [];
  acc[params.date].push(params);
  return acc;
}, {});

export const byAuthor = files.filter(imageFilter).reduce((acc, f) => {
  const params = extract(f);
  acc[params.author] = acc[params.author] || [];
  acc[params.author].push(params);
  return acc;
}, {});

export const allFiles = files.filter(imageFilter).map(extract);
