import React from "react";
import "./App.css";
import { Grid } from "react-virtualized";
import { allFiles } from "./files";
import config from "./config.json";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { useResponsive } from "./responsive";

const ImageCell = ({ image }) => {
  const thumbnail = btoa(
    JSON.stringify({
      bucket: config.bucket,
      key: image.filepath,
      edits: { resize: { width: 200, height: 200 } },
    })
  );
  return (
    <Link to={"/image/" + image.filepath}>
      <img
        width={200}
        height={200}
        src={config.cdn + "/" + thumbnail}
        alt={image.filename}
      />
    </Link>
  );
};

const ListView = () => {
  const { date } = useParams();
  const { windowSize } = useResponsive();
  const columnCount = Math.floor((windowSize - 20) / 220);
  const onSectionRendered = React.useCallback(({ rowStartIndex }) => {
    const newDate = allFiles[Math.floor(rowStartIndex * columnCount)].date;
    if (newDate !== date) {
      window.history.replaceState(null, "", `/${newDate}/`);
    }
  });
  const [scrollToRow, setScrollToRow] = React.useState();
  const cellRenderer = React.useCallback(
    ({ columnIndex, key, rowIndex, style }) => {
      const idx = rowIndex * columnCount + (columnIndex % columnCount);
      return (
        <div className="item" key={key} style={style}>
          <ImageCell image={allFiles[idx]} />
        </div>
      );
    },
    [columnCount]
  );
  React.useEffect(() => {
    if (!date) return;
    // find the first matching date, and set that as the row.
    const idx = allFiles.findIndex((item) => item.date >= date);
    if (idx < 0) return;
    setScrollToRow(Math.floor(idx / columnCount));
  }, []);
  return (
    <Grid
      cellRenderer={cellRenderer}
      columnCount={columnCount}
      columnWidth={220}
      rowHeight={220}
      width={220 * columnCount}
      onSectionRendered={onSectionRendered}
      height={window.innerHeight}
      scrollToRow={scrollToRow}
      rowCount={allFiles.length / columnCount}
    />
  );
};

const ImageView = () => {
  let { image } = useParams();
  image = "/" + image;
  return (
    <a href={image}>
      <img src={image} alt={image} />
    </a>
  );
};

function App() {
  return (
    <div className="centered">
      <div className="wrapper">
        <Router>
          <Switch>
            <Route path="/image/:image+">
              <ImageView />
            </Route>
            <Route path="/:date/">
              <ListView />
            </Route>
            <Route path="/">
              <ListView />
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
