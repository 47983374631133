import { useState, useMemo, useEffect } from "react";

export const BREAKPOINTS = {
  EXTRA_SMALL: 320,
  SMALL: 520,
  MEDIUM: 800,
  LARGE: 1024,
  EXTRA_LARGE: 1300,
};

const makeUseResponsive = () => {
  let bounded = false;
  const listeners = [];

  const getWindowWidth = () => {
    return typeof window !== "undefined" ? window.innerWidth : 0;
  };

  const bindToWindow = () => {
    if (!bounded && typeof window !== "undefined") {
      bounded = true;
      window.addEventListener("resize", () => {
        listeners.forEach((listener) => listener(getWindowWidth()));
      });
    }
  };

  return () => {
    const [windowSize, setWindowSize] = useState(getWindowWidth());

    useEffect(() => {
      const listener = (width) => setWindowSize(width);
      listeners.push(listener);
      bindToWindow();

      return () => {
        const position = listeners.indexOf(listener);
        if (position >= 0) listeners.splice(position, 1);
      };
    }, [setWindowSize]);

    return useMemo(
      () => ({
        isMobile: windowSize <= BREAKPOINTS.MEDIUM - 1,
        isTablet:
          windowSize <= BREAKPOINTS.LARGE - 1 &&
          windowSize >= BREAKPOINTS.MEDIUM,
        isComputer:
          windowSize <= BREAKPOINTS.EXTRA_LARGE - 1 &&
          windowSize >= BREAKPOINTS.LARGE,
        isLargeScreen: windowSize >= BREAKPOINTS.EXTRA_LARGE,
        windowSize,
      }),
      [windowSize]
    );
  };
};

export const useResponsive = makeUseResponsive();
